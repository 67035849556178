@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


@layer base {
  body {
    @apply font-sans text-text-100 bg-bg-100;
  }
  h1, h2, h3, h4, h5, h6 {
    @apply font-heading font-bold text-text-100;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.group:hover .group-hover\:block {
  display: block;
  animation: fadeIn 0.3s ease-in-out;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}
